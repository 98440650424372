import React, { useEffect } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol,
  MDBCardGroup,
} from 'mdb-react-ui-kit';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css'; // Import your custom CSS file for styling

export default function App() {

  const frame = {
    marginTop: '100px',
    marginBottom: '100px',
    padding: '50px', // Adjust padding for better mobile spacing
    width: '100%', // Adjust the width to your preference
    backgroundColor: '#f5f5f5',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundImage: 'url(https://images.unsplash.com/photo-1606161577560-f5852ef3cb87?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D) ',
    opacity: 0.8
  }

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with desired options
  }, []);

  return (
    <div style={frame} className="container">


      <MDBRow className="justify-content-center">
        <MDBCol md="10" xl="8" className="text-center">
          <h3 className="mb-4">Why Choose Us?</h3>
          <p className="mb-4 pb-2 mb-md-5 pb-md-0">
          Welcome to Lessons2Drive, where your journey to becoming a confident and skilled driver begins. With a wealth of experience delivering over 1800 Drive Tests as a License Testing Officer for Victorian Driver's License applicants, our team is dedicated to providing you with top-tier instruction and support.
          <br></br>
          <br></br>
          We possess in-depth knowledge of practical driving tests, understanding common mistakes made by applicants and offering valuable tips and tricks to enhance your performance. By drawing from our dual perspectives as both instructors and license testers, we customize our teaching approach to suit your individual experience and confidence level.
          <br></br>
          <br></br>
          At Lessons2Drive, we are committed to guiding you towards success on the road, ensuring that you not only pass your test with flying colors but also develop the skills needed for safe and responsible driving. Join us on this exciting journey and let us empower you to drive with confidence and competence.
Chamitha

          </p>
        </MDBCol>
      </MDBRow>

      <MDBCardGroup className='d-flex flex-wrap justify-content-center'>
        <MDBCard className='m-2' data-aos="fade-up">
          <MDBCardBody className='d-flex flex-column align-items-center justify-content-center'>
            <i class="far fa-handshake icon"></i>
            <MDBCardTitle>BEST SERVICE</MDBCardTitle>
            <MDBCardText className="center-text">
              We are the best in terms of services that we offer you. We ensure safety as our main priority, therefore, we are well equipped with professional and well-trained instructors.
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter />
        </MDBCard>

        <MDBCard className='m-2' data-aos="fade-up">
          <MDBCardBody className='d-flex flex-column align-items-center justify-content-center'>
            <i class="fas fa-users icon"></i>
            <MDBCardTitle>WE TRAIN ALL AGES</MDBCardTitle>
            <MDBCardText className="center-text">
              We have driving instructors who are certified. Apart from that, they are also fit both mentally as well as physically and have many years of driving experience.
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter />
        </MDBCard>

        <MDBCard className='m-2' data-aos="fade-up">
          <MDBCardBody className='d-flex flex-column align-items-center justify-content-center'>
            <i class="fas fa-business-time icon"></i>
            <MDBCardTitle>24*7 AVAILABILITY</MDBCardTitle>
            <MDBCardText className="center-text">
              Now is the perfect time to start your In Class, Online, or Behind The Wheel Training.Now is the perfect time to start your In Class, Online, or Behind The Wheel Training.
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter />
        </MDBCard>
      </MDBCardGroup>

      <MDBCardGroup className='d-flex flex-wrap justify-content-center'>
        <MDBCard className='m-2' data-aos="fade-up">
          <MDBCardBody className='d-flex flex-column align-items-center justify-content-center'>
            <i class="far fa-circle-check icon"></i>
            <MDBCardTitle>CALM INSTRUCTOR</MDBCardTitle>
            <MDBCardText className="center-text">
              This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter />
        </MDBCard>

        <MDBCard className='m-2' data-aos="fade-up">
          <MDBCardBody className='d-flex flex-column align-items-center justify-content-center'>
            <i class="fas fa-car-side icon"></i>
            <MDBCardTitle>BEST CAR</MDBCardTitle>
            <MDBCardText className="center-text">

              Both classroom and online driving classes offer a variety of cars for training, ensuring comprehensive and diverse learning .
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter />
        </MDBCard>

        <MDBCard className='m-2' data-aos="fade-up">
          <MDBCardBody className='d-flex flex-column align-items-center justify-content-center'>
            <i class="fas fa-medal icon"></i>
            <MDBCardTitle>99% PASS RATE</MDBCardTitle>
            <MDBCardText className="center-text">
              Success rate of our students is very high as compared to other driving schools in Melbourne.

              Our Customers Say…
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter />
        </MDBCard>
      </MDBCardGroup>
    </div>
  );
}
