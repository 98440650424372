import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from './images/34.png';  // Replace with the actual path to your logo image

function NavScrollExample() {
  return (
    <Navbar expand="lg" style={{ background: '#e9e6e4' }}>
      <Container fluid>
        {/* <img
          src={Logo}      
          alt="Logo"
          style={{ height: '60px', marginLeft: '-10px',marginRight:'50px', marginTop: '-1px' }}
          sx={{ display: { xs: 'none', sm: 'none' } }}
        />
        */}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
          </Nav>
         
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
