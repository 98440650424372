import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Logo from './images/35.png';  // Replace with the actual path to your logo image
import LogoTwo from './images/34.png';  
export default function App() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-2 me-lg-5'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='https://www.facebook.com/profile.php?id=100085041280747' className='me-2 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='' className='me-2 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-2 text-reset'>
            <MDBIcon fab icon="google" />
          </a>
          <a href='' className='me-2 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='' className='me-2 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          {/* <a href='' className='me-2 text-reset'>
            <MDBIcon fab icon="github" />
          </a> */}
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-3'>
          <MDBRow>
            <MDBCol size='12' md='6' lg='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-2" />
                     {/* Wrap the logo with an anchor tag */}
            <img
              src={LogoTwo}
              alt="Logo"
              style={{ height: '60px', marginLeft: '-25px', marginTop: '-1px' }}
              sx={{ display: { xs: 'none', sm: 'none' } }}
            />
              <img
              src={Logo}
              alt="LogoTwo"
              style={{ height: '60px',padding:'5px', marginLeft: ' 20px', marginTop: '9px' }}
              sx={{ display: { xs: 'none', sm: 'none' } }}
            />
              </h6>
              <p>
              Unlock a world of confident driving with LessontTwoDrive. With 15 years of expertise, we are your trusted choice for safe and skilled driving education. Join us on the road to success.
              </p>
            </MDBCol>
{/* 
            <MDBCol size='12' md='6' lg='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Angular
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  React
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Vue
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Laravel
                </a>
              </p>
            </MDBCol> */}

            {/* <MDBCol size='12' md='6' lg='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Pricing
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Settings
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Orders
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Help
                </a>
              </p>
            </MDBCol> */}

            <MDBCol size='12' md='6' lg='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Coco Circuit Point Cook VIC 3030
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-2" />
                Info@Lessons2Drive.com.au
              </p>
              <p>
                <MDBIcon icon="phone" className="me-2" />  0400 008 7806
              </p>
              {/* <p>
                <MDBIcon icon="print" className="me-2" /> + 01 234 567 89
              </p> */}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
      © 2024 LessontTwoDrive Driving School. - Website Design by  
        <a className='text-reset fw-bold' href='https://24streets.com'>
        24streets 
        </a>
      </div>
    </MDBFooter>
  );
}
