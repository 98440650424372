import React from 'react';

import bannerImg from './images/banner.jpg'

import './banner.css';

function banner(){

    return (
        <div className="banner">
           <img src={bannerImg} alt="Banner" className="banner-img" />
        </div>
 );

}

export default banner;
