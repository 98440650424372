import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Carousel from './carousel';
import Findus from './findus';
import Pricing from './pricingPackages';
import WhyyouChooseUS from './cards';
import Testimonial from './testimonial';
import Chat from './chat';
import Packages from './packages';
import PhotosGallery from './photosGallery';
import AboutUs from './aboutUs';
import Banner from './banner';
import SectionTwo from './sectionTwo';


const Home = () => {
    return (
        <div>
            <Header />
            {/* <Carousel/> */}
            <Banner />
            <Chat />
            <AboutUs />
            <WhyyouChooseUS />
            <SectionTwo/>
            <Pricing />
            <Findus />
            <PhotosGallery />
            <Testimonial />
            <Footer />
        </div>
    );
};

export default Home;
