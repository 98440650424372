import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBIcon,
} from 'mdb-react-ui-kit';

const backgroundImageStyle = {
  backgroundImage: `url('https://images.unsplash.com/photo-1596527199903-6cdaacee1208?q=80&w=3267&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '150vh',
  width: '100%',
  zIndex: '-1',
  position: 'relative',
  marginTop: '-24px',
};

function App() {
  return (
    <MDBContainer fluid className='mt-5' style={backgroundImageStyle}>
      <section className='background-radial-gradient overflow-hidden'>
        <div style={{marginTop:'120px'}} className='container px-2 px-md-15 text-center text-lg-start my-5'>
          <div className='row gx-lg-5 align-items-center mb-5'>
            <div className='col-lg-6 mb-5 mb-lg-0'>
              <h1 className='my-5 display-3 fw-bold ls-tight' style={{ color: '#098cf7' }}>
                Find Driving Course <br />
                <span style={{ color: '#c57021' }}>Best Options For You</span>
              </h1>
            
            </div>

            <div className='col-lg-6 position-relative'>
              <MDBCard className='bg-glass' style={{ padding: '20px' }}>
                <MDBCardBody className='px-4 py-5'>
                  <form>
                    <MDBRow className='mb-4'>
                      <MDBCol size='12' md='6'>
                        <MDBInput className='mb-3' id='first2' label='First name' />
                      </MDBCol>
                      <MDBCol size='12' md='6'>
                        <MDBInput className='mb-3' id='last2' label='Last name' />
                        
                      </MDBCol>
                    </MDBRow>

                    <MDBInput style ={{marginTop:'-20px'}}className='mb-4' type='email' id='email2' label='Email address' />

                    <MDBRow className='mb-4 align-items-center'>
                      <MDBCol md='8'>
                        
                      
                      </MDBCol>
                    </MDBRow>

                    <MDBBtn type='submit' block className='mb-4' color='primary' style={{marginTop:'-10px'}}>
                      Submit
                    </MDBBtn>

                    <div className='text-center'>
                      <p>or sign up with:</p>
                      <div className="d-flex justify-content-center">
                        <MDBBtn type='button' floating className='mx-1' color='blue'>
                          <MDBIcon fab icon='facebook-f' />
                        </MDBBtn>

                        <MDBBtn type='button' floating className='mx-1' color='danger'>
                          <MDBIcon fab icon='google' />
                        </MDBBtn>

                        <MDBBtn type='button' floating className='mx-1' color='info'>
                          <MDBIcon fab icon='twitter' />
                        </MDBBtn>

                        <MDBBtn type='button' floating className='mx-1' color='dark'>
                          <MDBIcon fab icon='github' />
                        </MDBBtn>
                      </div>
                    </div>
                  </form>
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
        </div>
      </section>
    </MDBContainer>
  );
}

export default App;
