import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import image3 from './images/image3.jpg'
import { fontSize } from '@mui/system';

AOS.init();

const AboutUs = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        // Initial check
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const highlightStyle = {
        fontWeight: 'bold',
      };

    const styles = {
        container: {
            position: 'relative',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: '0 10px',
        },
        backgroundBlur: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'url("https://images.unsplash.com/photo-1506973035872-a4ec16b8e8d9?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") center center / cover',
            filter: 'blur(30px)',
            zIndex: -1,
            borderRadius: '10px',
            border: '10px solid #ccc',
            opacity: 0.5,
        },
        content: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            margin: '3% 3%',
            position: 'relative',
            boxSizing: 'border-box',
            maxWidth: '100%',
        },
        description: {
            flex: 1,
            marginTop: '40px',
            padding: '20px',
            borderRight: isMobile ? 'none' : '1px solid #ccc',
            position: 'relative',
            zIndex: 2,
            fontWeight: 'normal',
            boxSizing: 'border-box',
            maxWidth: '100%',
            fontSize: '18px'
        },
        image: {
            flex: 1,
            position: 'relative',
            zIndex: 1,
            //display: isMobile ? 'none' : 'block',
            boxSizing: 'border-box',
            maxWidth: '100%',
            overflow: 'hidden',
        },
        imageElement: {
            width: '100%',
            marginTop: '50px',
            height: 'auto',
            boxShadow: '0 4px 8px rgba(245, 0, 0, 0.1)',
            border: '1px solid #ccc',
            position: 'relative',
            zIndex: 1,
            boxSizing: 'border-box',
        },
        
    };

    return (
        <div style={styles.container}>
            <div style={styles.backgroundBlur}></div>
            <div style={styles.content}>
                <div data-aos="fade-right" style={styles.description}>
                    <h1 style={{ marginBottom: '15px' }}>About Us</h1>
                    <p>
                        Lessons2Drive Driving School was established by a team of <span style={highlightStyle}>EX - VICROADS LICENCE TESTING OFFICERS</span>{' '}
                         to help you hit the road with confidence and become a skilled driver.
                    </p>
                    <p>
                        Getting your learner’s permit, then taking your drive exam and finally getting behind the wheel
                        for the first time: these are the key milestones to driving independence.
                    </p>
                    <p>
                        But learning to drive is no easy task. You’re always thinking about the road rules, other
                        people, their cars and what would happen if you did A instead of B but nothing can lap the fact
                        you’re now in charge of a moving vehicle.
                    </p>
                    <p>
                        Sure, you’re excited to drive, but you’re probably a little nervous too - and that’s okay. The
                        right instructor will transform nerves into nous, ensuring you drive away with confidence.
                    </p>
                    <p>
                        Driving instructors are meant to help you learn the basics of driving in a safe, comfortable and
                        fun environment. They should be patient, have good communication skills and determination to
                        guide you through the tricky process of learning how to drive.
                    </p>
                </div>
                <div data-aos="fade-left" style={styles.image}>
                    <img
                        src={image3}
                        alt="Driving School"
                        style={styles.imageElement}
                    />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
