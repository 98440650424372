import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import IMG1 from './images/image1.jpg';
import IMG2 from './images/image2.jpg';
import IMG4 from './images/image4.jpg';
import IMG5 from './images/image5.jpg';
import IMG6 from './images/image6.jpg';


export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);


  return (


    <MDBContainer className="py-5">
      <MDBRow className="d-flex justify-content-center">

        <MDBCol md="10" xl="8" className="text-center" data-aos="fade-up">
          <h3 className="mb-4">Our Customers Say…</h3>
        </MDBCol>

      </MDBRow>
      <MDBRow className="text-center d-flex align-items-stretch" data-aos="fade-up"  >
        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
          <MDBCard className="testimonial-card"   >
            <div
              className="card-up"

            ></div>
            <div className="avatar mx-auto bg-white" style={{ border: '2px solid #fff', padding: '45px', borderRadius: '50%' }}>
              <img
                src={IMG1}
                className="rounded-circle img-fluid"
              />
            </div>
            <MDBCardBody>
              <h4 className="mb-4">Kavi</h4>
              <hr />
              <p className="dark-grey-text mt-4">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                A big thank to Mr. Chamitha  for helped me to get my license on my first attempt.I went from Burwood to Werribee for my lessons and it's totally worth it.I started as a beginner and I practiced only about 2 months with Chamitha Ayya. If you want to become a safe driver who doesn't bother other people on the road then you should definitely go to him. He's not only focus about driving test, he makes you a safe driver. Also he's very knowledgeable and easy to communicate.
                I highly recommend lessons2 Drive driving school for anyone who's looking to get their license. They are the best!
                <MDBIcon fas icon="quote-right" style={{ marginLeft: '5px' }} /></p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
          <MDBCard className="testimonial-card">
            <div
              className="card-up"
              style={{ backgroundColor: "#7a81a8" }}
            ></div>
            <div className="avatar mx-auto bg-white" style={{ border: '2px solid #fff', padding: '45px', borderRadius: '50%' }}>
              <img
                src={IMG2}
                className="rounded-circle img-fluid"
              />
            </div>
            <MDBCardBody>
              <h4 className="mb-4">Isuri</h4>
              <hr />
              <p className="dark-grey-text mt-4">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                I am really grateful to Chamitha and Lessons2Drive team for all the help and support. Chamitha is an excellent instructor. I got through on my first attempt after a few lessons. With my work schedule, it was very difficult to schedule lessons. Lessons2Drive team is extremely flexible and they don't charge extra for rescheduling. Any test can be nerve wracking but Chamitha helps a lot to face the test with a clear head. He doesn't stress out learners with undue criticism and the learner can make maximum use of the allotted lesson time.
                <MDBIcon fas icon="quote-right" style={{ marginLeft: '5px' }} /></p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
          <MDBCard className="testimonial-card">
            <div
              className="card-up"
              style={{ backgroundColor: "#6d5b98" }}
            ></div>
            <div className="avatar mx-auto bg-white" style={{ border: '2px solid #fff', padding: '45px', borderRadius: '50%' }}>
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp"
                className="rounded-circle img-fluid"
              />
            </div>
            <MDBCardBody>
              <h4 className="mb-4">Jessica</h4>
              <hr />
              <p className="dark-grey-text mt-4">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                Cham is a fully trained ex-LTO from VicRoads and knows his stuff! On top of that he is extremely patient, kind and fair. I had the privilege of working with Cham (both as coworker and manager at VR) for around 2 years and know that he does whatever it takes to get the required result, in a safe and respectable manner. You are sure to be in great hands with Lessons2Drive Driving School!
                <MDBIcon fas icon="quote-right" style={{ marginLeft: '5px' }} /></p>
            </MDBCardBody>

          </MDBCard>
        </MDBCol>

      </MDBRow>
      <div style={{marginTop: '10px'}}>
        <MDBRow className="text-center d-flex align-items-stretch" data-aos="fade-up"  >
          <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
            <MDBCard className="testimonial-card"   >
              <div
                className="card-up"

              ></div>
              <div className="avatar mx-auto bg-white" style={{ border: '2px solid #fff', padding: '45px', borderRadius: '50%' }}>
                <img
                  src={IMG4}
                  className="rounded-circle img-fluid"
                />
              </div>
              <MDBCardBody>
                <h4 className="mb-4">Darshana</h4>
                <hr />
                <p className="dark-grey-text mt-4">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                 Chamitha is an exceptional driving instructor who turned my initial apprehension into confidence. As a complete beginner, I felt unsure about driving, but Chamitha's kindness, empathy, and patience made me comfortable from day one. He meticulously identified and addressed my weaknesses, guiding me with professionalism and ensuring my safety. His approach wasn't rushed, allowing me to progress at my own pace. I trusted his judgment, and with his guidance, I passed my test successfully. Chamitha emphasizes that learning is not just for passing the test but for life, showcasing his dedication. I highly recommend him for making the learning journey easy and worthwhile. My heartfelt thanks to Chamitha, and I wish him the best in his future endeavors.
                 <br></br>
                 I wholeheartedly recommend Chamitha and his driving school, Lesson2Drive, to fellow learners. His exceptional teaching style, coupled with genuine dedication, transforms the learning experience. Choosing Chamitha ensures not just driving proficiency but a supportive and personalized journey. If you're seeking a skilled instructor who prioritizes your comfort, safety, and overall success, Lesson2Drive with Chamitha is the way to go
                  <MDBIcon fas icon="quote-right" style={{ marginLeft: '5px' }} /></p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
            <MDBCard className="testimonial-card">
              <div
                className="card-up"
                style={{ backgroundColor: "#7a81a8" }}
              ></div>
              <div className="avatar mx-auto bg-white" style={{ border: '2px solid #fff', padding: '45px', borderRadius: '50%' }}>
                <img
                  src={IMG5}
                  className="rounded-circle img-fluid"
                />
              </div>
              <MDBCardBody>
                <h4 className="mb-4">Dr Mahnoor</h4>
                <hr />
                <p className="dark-grey-text mt-4">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  I recently passed my driving test thanks to the outstanding instruction provided by my driving instructor Cham from Lessons2Drive Driving School. From the very first lesson to the day of my test, Cham was incredibly patient, knowledgeable, and supportive. I moved to Melbourne in January 2023 and prior to that had limited driving experience overseas.
                   <br></br>
Cham's teaching style is clear and effective, making even the most challenging maneuvers seem manageable. Cham took the time to understand my strengths and weaknesses, tailoring each lesson to focus on areas where I needed improvement, which greatly boosted my confidence behind the wheel. He is sincere with his efforts, very professional and an excellent human being. He is very calm and collected with his approach. I work shift work and Cham was very understanding with scheduling lessons around my routine. This was all greatly appreciated.
 <br></br>
One thing that stood out to me was his dedication to safety. Cham emphasized the importance of defensive driving and instilled in me the skills needed to anticipate and react to various road situations. The most important lesson is to be a safe driver, and not to be a source of danger to yourself and others.
 <br></br>
Moreover, Cham created a positive and comfortable learning environment, which made every lesson enjoyable and productive. His encouragement and constructive feedback motivated me to strive for excellence and overcome all the hurdles along the way.
I am very grateful for all of Cham’s support. I wholeheartedly recommend Cham to anyone seeking a brilliant driving instructor to provide you with all the necessary skills, competence and confidence. Thank you for everything once again!
                  <MDBIcon fas icon="quote-right" style={{ marginLeft: '5px' }} /></p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
            <MDBCard className="testimonial-card">
              <div
                className="card-up"
                style={{ backgroundColor: "#6d5b98" }}
              ></div>
              <div className="avatar mx-auto bg-white" style={{ border: '2px solid #fff', padding: '45px', borderRadius: '50%' }}>
                <img
                   src={IMG6}
                  className="rounded-circle img-fluid"
                />
              </div>
              <MDBCardBody>
                <h4 className="mb-4">Dr Poorna</h4>
                <hr />
                <p className="dark-grey-text mt-4">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  I relocated to Melbourne  10months back. Although I have the driver’s license , I never drive in Sri Lanka (only driven for the driver’s test). I didn’t want to have even a single lesson here  due to my anxiety of driving.
                  <br></br>
But, I recently had the pleasure of taking driving lessons with Chamitha, and I cannot express how grateful I am for the exceptional experience. From the very beginning, Chamitha demonstrated a level of professionalism and expertise that instantly put me at ease.
 <br></br>
His teaching approach was not only informative but also tailored to my individual learning style. Chamitha was patient, encouraging, and always provided constructive feedback. The lessons were well-structured, covering everything from basic driving skills to more complex maneuvers, ensuring that I felt confident and capable behind the wheel.
 <br></br>
One aspect that stood out was Chamitha's ability to create a positive and supportive learning environment. His calm demeanor and friendly attitude made each lesson enjoyable, even during challenging moments. I appreciated the open communication, as Chamitha took the time to address any concerns I had and answer all my questions thoroughly.
 <br></br>
Additionally, Chamitha was punctual and flexible with scheduling, making the overall experience convenient and stress-free. His dedication to ensuring my success in obtaining a driver's license was evident throughout the entire process.
 <br></br>
I highly recommend Chamitha to anyone in need of a driving instructor. With his expertise, patience, and positive attitude, he not only taught me how to drive safely but also instilled in me a sense of confidence and responsibility on the road. Thank you, Chamitha, for making the learning experience so enjoyable and for helping me become a confident and skilled driver.
                  <MDBIcon fas icon="quote-right" style={{ marginLeft: '5px' }} /></p>
              </MDBCardBody>

            </MDBCard>
          </MDBCol>

        </MDBRow>
      </div>
    </MDBContainer>
  );
}