import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const containerStyle = {
    marginTop: '90px', // Adjust the margin-top as needed
  };

  const colStyle = {
    marginBottom: '-1rem', // Adjust the margin-bottom for the images
  };

  return (
    <MDBContainer data-aos="fade-down" style={containerStyle}>
      <MDBRow className='row-cols-1 row-cols-md-2 row-cols-lg-3 g-4'>
        <MDBCol style={colStyle}>
          <img
            src='https://i.ibb.co/TPX3kSK/1.jpg'
            className='w-100 shadow-1-strong rounded mb-2'
            alt='Boat on Calm Water'
          />

          <img
            src='https://i.ibb.co/5czCVkh/3.jpg'
            className='w-100 shadow-1-strong rounded mb-2'
            alt='Wintry Mountain Landscape'
          />
        </MDBCol>

        <MDBCol style={colStyle}>
          <img
            src='https://i.ibb.co/Cm3MrXq/4.jpg'
            className='w-100 shadow-1-strong rounded mb-2'
            alt='Mountains in the Clouds'
          />

          <img
            src='https://i.ibb.co/0VpsCrp/2.jpg'
            className='w-100 shadow-1-strong rounded mb-2'
            alt='Boat on Calm Water'
          />
        </MDBCol>

        <MDBCol style={colStyle}>
          <img
            src='https://i.ibb.co/V3d30Bx/IMG-1333.jpg'
            className='w-100 shadow-1-strong rounded mb-2'
            alt='Waves at Sea'
          />

          <img
            src='https://i.ibb.co/N6PKwSN/IMG-1707.jpg'
            className='w-100 shadow-1-strong rounded mb-2'
            alt='Yosemite National Park'
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
