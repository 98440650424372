// TawkToChat.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const TawkToChat = () => {
  useEffect(() => {
    // Tawk.to script
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];

    s1.async = true;
    s1.src = 'https://embed.tawk.to/66a1be8bbecc2fed692abd27/1i3jt0knt';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');

    s0.parentNode.insertBefore(s1, s0);

    return () => {
      // Cleanup script when component unmounts
      s1.parentNode.removeChild(s1);
    };
  }, []);

  return (
    <Helmet>
      {/* Empty component to use Helmet to insert the script into the head */}
    </Helmet>
  );
};

export default TawkToChat;
