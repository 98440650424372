import React from 'react';

import sectionTwoImg from './images/bannertwo.png'

import './sectionTwo.css';


function sectionTwo() {

    return (
        <div className="sectiontwo">
           <img src={sectionTwoImg} alt="SectionTwo" className="section-img" />
        </div>
    );

}

export default sectionTwo;