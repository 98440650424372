import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/home';

import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from './Components/carousel';
import Findus from './Components/findus';
import Price from './Components/pricingPackages';
import Card from './Components/cards';
import Testimonial from './Components/testimonial';
import Chat from './Components/chat';
import Packages from './Components/packages';
import PhotosGallery from './Components/photosGallery';

export default class App extends Component {
  render() {
    return (
     
      <Router>   
      <Routes>
        <Route path='' exact Component={Home}/>
        <Route path='findus' exact Component={Findus}/>
      </Routes>
      </Router>
      
    )
  }
}
